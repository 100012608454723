@mixin glass {
    background: rgba(255, 255, 255, 0.28) !important;
    box-shadow: 20px 20px 40px -6px rgba(0, 0, 0, 0.5) !important;
    backdrop-filter: blur(4.5px) !important;
    -webkit-backdrop-filter: blur(4.5px) !important;
    border-radius: 20px !important;
}

@mixin dark-glass {
    background: rgba(0, 0, 0, 0.5) !important;
    box-shadow: 20px 20px 40px -6px rgba(0, 0, 0, 0.2) !important;
    backdrop-filter: blur(4.5px) !important;
    -webkit-backdrop-filter: blur(4.5px) !important;
    border-radius: 20px !important;
    color: $white;
}

@mixin remove-glass {
    background: none !important;
    box-shadow: none !important;
    backdrop-filter: none !important;
    -webkit-backdrop-filter: none !important;
}

@mixin shadow {
    -webkit-box-shadow: 5px 5px 25px 5px rgba(0, 0, 0, 0.76);
    box-shadow: 5px 5px 25px 5px rgba(0, 0, 0, 0.76);
}