.fluid-type {
    font-size: 2.1rem;
    grid-area: 1 / 1 / 1 / 3;
    margin-bottom: 20px;
}

.home-page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.landing-wrapper {
    padding: 0px 1% 0px 1%;
    display: grid;
    grid-template-columns: 0.7fr 1fr;
    grid-template-rows: auto;
    gap: 10px 0px;
    place-items: center;
    text-align: center;

    .yummy-desc {
        padding: 50px;
    }

    .home-button-wrapper {
        grid-area: 3 / 1 / 5 / 1;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        gap: 15px;

        .btn {
            display: flex;
            background-color: $orange;
            color: $blue;
            border-color: $white;
            border-radius: 15px;
            -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.3);
            box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.3);
            text-align: center;
            align-items: center;
            justify-content: center;
        }

    }

    .home-top-button {
        grid-area: 1 / 1 / 1 / 3 !important;
    }

    .video-wrapper {
        grid-area: 2/ 2 / 5 / 2;
        padding: 20px;

        iframe {
            @include glass;
            -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.46);
            box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.46);
            border-radius: 20px;
            padding: 10px;
        }

        .contract-address {
            padding-top: 10px;
            color: $orange;

            span {
                color: $white;
            }
        }
    }
}



.divider {
    padding: 100px 20px 60px 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 100px 5%;
    place-items: center;
    background-color: $white;
    color: $blue;
    -webkit-box-shadow: 0px 0px 29px 16px rgba(0, 0, 0, 0.48);
    box-shadow: 0px 0px 29px 16px rgba(0, 0, 0, 0.48);

    .transparent-logo {
        width: 100%;
        height: fit-content;
    }

    .divider-text-wrapper {
        grid-area: 1 / 2 / 2 / 4;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        flex-direction: column;

    }


    .milestones-wrapper {
        grid-area: 2 / 1 / 2 / 4;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: 100%;
        place-items: center;
        gap: 50px 100px;
        text-align: center;
        padding: 0px 50px 0px 50px;

        .milestone {
            display: flex;
            flex-direction: column;
            width: 100%;
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;
        }

        .milestone-title {
            border-bottom: 10px solid $blue;
            border-radius: 20px;
        }
    }
}

.wave {
    position: relative;
    margin-top: -1px;
    width: 100%;
}


.text-sprout-wrapper {
    @include glass;
    display: grid;
    grid-template-columns: 1fr 0.6fr;
    grid-template-rows: 1fr;
    place-items: center;
    width: 80%;
    margin: 100px;
    padding: 60px;

    .sprout-text {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .sprout-image {
        grid-column-start: 2;
        width: fit-content;
        height: fit-content;
    }
}