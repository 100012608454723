.nft-page-container {
    display: grid;
    place-items: center;
    grid-template-columns: 0.75fr 1fr;
    grid-template-rows: auto;
    padding-bottom: 200px;
    text-align: center;
    padding: 0px 40px 40px 40px;
    gap: 20px 0px;
}

.why-NFT-head {
    grid-area: 1 / 1 / 1 / 3;
    margin-bottom: 40px;
}

.yummy-dog-carousel {
    grid-area: 2 / 2 / 3 / 2;
}

.yummy-dog {
    width: 50% !important;
    border-radius: 50px;
    border: solid 5px $orange;
    -webkit-box-shadow: 5px 5px 0px 5px $blue;
    box-shadow: 3px 3px 0px 3px $blue;
}

.nft-header-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.NFT-description {
    text-align: center;
    width: 100%;
}

.nft-reasons-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 50px;
}


.mint-btn-link {
    grid-area: 3 / 1 / 3 / 3;
    width:30%;

    img {
        width: 100%;
    }
}

.FAQ-container {
    padding-top:100px;
    width: 80%;
    color: $blue;
    grid-area: 4 / 1 / 4 / 3;
}

.accordion {
    @include dark-glass;
}


.accordion-button {
    // @include glass;
    background: none !important;
    border-radius: 20px !important;
    color: $white !important;

    &:focus {
        box-shadow: none !important;
        border: 1px solid $white !important;
    }

}

.accordion-button:not(.collapsed) {
    color: $white !important;
}

.accordion-item {
    // @include glass;
    background: none !important;
    border-bottom: 1px solid black !important;
}

.accordion-body {
    color: $orange;
}