@media only screen and (max-width: 1560px) {
    .landing-wrapper {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 1450px) {
    .landing-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;

        .yummy-desc {
            padding: 20px;
        }

        .video-wrapper {
            grid-area: 3 / 1 / 3 / 1;
        }

        .home-button-wrapper {
            padding: 40px 40px 40px 40px;
            grid-area: 5 / 1 / 5 / 1;
        }
    }
}

@media only screen and (max-width: 1200px) {

    .nft-page-container {
        grid-template-rows: auto;
    }

    .NFT-description {
        grid-area: 2 / 1 / 2 / 3;
    }

    .yummy-dog-carousel {
        grid-area: 3 / 1 / 3 / 3;

    }

    .mint-btn-link {
        width: 100%;
        grid-area: 4 / 1 / 4 / 3;
    }

    .FAQ-container {
        grid-area: 5 / 1 / 5 / 3;
    }


}

@media only screen and (max-width: 992px) {
    .container {
        justify-content: center !important;
    }

    .navbar-toggler {
        position: fixed;
        right: 20px;
        bottom: 20px;
        background-color: $orange !important;
        border-radius: 80px !important;
        border: $white 2px solid !important;
        padding: 1px !important;
    }

    .hamburger-react {
        margin: 3px;
    }

    .navbar-collapse {
        position: fixed !important;
        right: 10px;
        bottom: 100px;
        @include glass;
        background: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(8px);
        -webkit-backdrop-filter: blur(8px);
        height: 500px;
        width: 300px;
        font-size: 20px;
        display: flex;
        padding: 10px;
        border: rgba(255, 255, 255, 0.15) 1px solid;
        z-index: 1;
        -webkit-box-shadow: -6px -3px 28px 5px rgba(0, 0, 0, 0.7) !important;
        box-shadow: -6px -3px 28px 5px rgba(0, 0, 0, 0.7) !important;

        #basic-nav-dropdown {
            color: $blue !important;
        }

        .dropdown-menu.show {
            @include dark-glass;
        }
    }

    .nav-socials {
        i {
            color: $blue !important;
        }
    }


    .me-auto.navbar-nav {
        height: 100%;
        justify-content: space-around;
        align-items: baseline;
        color: $blue !important;

        .nav-link {
            color: $blue !important;
        }
    }

    .button-wrapper {
        font-size: 14px;

        button {
            font-size: 14px;
        }
    }

    .video-wrapper {
        width: 100%;
        padding: 0px !important;
        @include remove-glass;

        iframe {
            width: 100%;

        }
    }

    .partner-grid {
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));

        img {
            width: 100%;
            padding: 10px;
        }
    }

    .dropdown-menu {
        grid-template-columns: 1fr 0.5fr;
        width: 100%;
    }

    .nav-socials {
        flex-wrap: wrap;


        i {
            font-size: 2.5em;
        }
    }

    .tab-pane {
        grid-template-columns: 1fr !important;
    }

    .truststep,
    .metastep {
        display: none;
    }


}

@media only screen and (max-width: 850px) {

    .landing-buttons-wrapper {
        padding: 0px !important;
    }

    .button-wrapper {
        font-size: 12px;
        gap: 30px 10px !important;
    }

    .landing-wrapper {
        margin: 10px;
    }

    .text-sprout-wrapper {
        grid-template-columns: 1fr;
        padding: 20px;
    }

    .sprout-text {
        text-align: center;
    }

    .sprout-image {
        display: none;
    }

    .transparent-logo {
        display: none;
    }

    .divider-text-wrapper {
        text-align: center;
        grid-area: 1 / 1 / 2 / 4 !important;
    }

    .milestones-wrapper {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)) !important;
        grid-template-rows: 1fr 1fr;

    }

}

@media only screen and (max-width: 700px) {
    .yummy-dog {
        width: 100% !important;
    }

    .nft-reasons-container {
        padding: 0px;
    }
}

@media only screen and (max-width: 600px) {}

@media only screen and (max-width: 500px) {

    .milestones-wrapper {
        padding: 0px !important;
    }

    .landing-wrapper {
        padding: 0px;
    }

    .app-wrapper {
        padding: 0px;
    }

    .nft-container {
        padding: 0px;
    }

    .NFT-description {
        width: 100%;
    }

    .modal-content {
        width: 100% !important;
    }

    .FAQ-container {
        width: 100%;
    }

    .nft-page-container {
        row-gap: 100px;
    }

}